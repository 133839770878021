<template>
  <div>
    {{ msg }}
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import toastMsg from "@/components/accounts/toast-message.vue";

export default {
  data() {
    return {msg: ""};
  },
  mounted() {
    let url = sessionStorage.getItem("mysoho_interlock_callback");
    this.$refs.toast.show(" 솔루션 연동이 완료되었습니다.");

    setTimeout(() => {
      if (typeof url != "string" || !url.length) {
        this.msg = JSON.stringify(this.$route.params);
      } else {
        location.replace(
          url +
            "?success=" +
            (this.$route.params.success === true ||
            this.$route.params.success === "true"
              ? 1
              : 0) +
            "&msg=" +
            this.$route.params.msg +
            "&apikey=" +
            this.$route.params.apikey +
            "&shopid=" +
            this.$route.params.shopid
        );
      }
    }, 3000);
  },
  components: {
    toastMsg
  }
};
</script>

<style></style>
