var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" " + _vm._s(_vm.msg) + " "), _c('toastMsg', {
    ref: "toast"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }